const licenseTypes = [
  {
    value: 'software',
    text: 'Software',
  },
  {
    value: 'hardware',
    text: 'Hardware',
  },
  {
    value: 'service',
    text: 'Serviços',
  },
]
export default licenseTypes
