<template>
  <div>
    <b-form-group
      :label="labelForm"
      :label-for="name"
    >
      <v-select
        v-model="tagsAvailable"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        multiple
        taggable
        push-tags
        :options="options"
        :placeholder="placeholder"
        label="name"
        @input="changeTags"
      />
    </b-form-group>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  name: 'I2FormTags',
  components: {
    vSelect,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    context: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: 'tags',
    },
    labelForm: {
      type: String,
      default: 'Tags',
    },
    placeholder: {
      type: String,
      default: 'Adicione Tags',
    },
  },
  data() {
    return {
      model: {
        name: '',
        uuid: null,
      },
      tagsAvailable: [],
      options: [],
    }
  },
  watch: {
    tagsAvailable() {
      this.$emit('onChange', this.tagsAvailable)
    },
    value(value) {
      if (value) {
        this.tagsAvailable = value
      }
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.options = []
      this.$http.get(`tags/context/${this.context}`).then(
        response => {
          response.forEach(data => {
            this.options.push({ name: data.name, uuid: data.uuid })
          })
        },
      )
    },
    changeTags() {
      this.tagsAvailable.forEach(data => {
        if (!data.uuid) {
          this.model.name = data.name ? data.name : data
          this.save()
        }
      })
    },
    async save() {
      const response = await this.$http.post(`tags/context/${this.context}`, this.model)
      if (response.error) {
        this.$notify.error(response.error_message)
        return
      }

      const index = this.tagsAvailable.findIndex(x => x.name === response.name)
      if (this.tagsAvailable.length > 0 && index !== -1) {
        this.tagsAvailable[index].uuid = response.uuid
      }
      this.model = {
        name: '',
        uuid: null,
      }
    },
  },
}
</script>
